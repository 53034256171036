import React from 'react';
import CustomerSnippet from '../../global/customer-snippet/CustomerSnippet';
import Title from '../../global/title/Title';
import * as S from './styles';

const CustomerHolder = ({
	title,
	customers,
	isUsecase = false,
	marginEffect = true,
	isLeadershipSection = false,
}) => {
	const Snip = ({ customer, id }) => {
		if (!isUsecase) {
			return (
				<CustomerSnippet
					id={id}
					image={customer.cardImage}
					logo={customer.cardLogo}
					title={customer.title}
					slug={`customers/${customer.slug}`}
					data={customer.data}
				/>
			);
		} else {
			return (
				<CustomerSnippet
					id={id}
					image={customer.homepageImage}
					title={customer.section1Title}
					slug={customer.slug && `use-cases/${customer.slug}`}
					isUsecase={isUsecase}
					isPopular={customer.popular}
				/>
			);
		}
	};

	return (
		<S.Wrapper isUsecase={isUsecase} isLeadershipSection={isLeadershipSection}>
			{!isUsecase && !isLeadershipSection && (
				<S.TitleWrapper>
					<Title title={title} />
				</S.TitleWrapper>
			)}
			{isLeadershipSection && <S.LeadershipTitle>{title}</S.LeadershipTitle>}
			<S.CustomerHolder
				isLeadershipSection={isLeadershipSection}
				isUsecase={isUsecase}
				marginEffect={marginEffect}
			>
				{customers.map((customer, id) => (
					<Snip customer={customer} id={id} />
				))}
			</S.CustomerHolder>
		</S.Wrapper>
	);
};

export default React.memo(CustomerHolder);
